import styled from 'styled-components'
import scssVars from '~components/scss-variables'

const SectionHeader = styled.h2`
  margin-bottom: 0.5rem;
  font-weight: 300;
  line-height: 1.2;
  font-size: 28px;
  color: ${scssVars.darkBrown};
`

export default SectionHeader
