import { Link }  from 'gatsby'

import styled from 'styled-components'
import scssVars from '~components/scss-variables'

const LinkText = styled(Link)`
  font-size: 14px;
  line-height: 20px;
  font-style: italic;
  font-weight: 400;
  color: ${scssVars.orange};
  text-transform: initial;
  text-decoration: underline;
`

export default LinkText
