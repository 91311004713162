import React, { useState } from 'react'
import { graphql, Link } from 'gatsby'
import PropTypes from 'prop-types'
import { Container, Row, Col } from 'reactstrap'
import styled from 'styled-components'

import Layout from '~components/Layout'
import scssVars from '~components/scss-variables'
import { FancyLineHeading } from '~components/LayoutComponents'
import { Breadcrumbs, MobileBreadcrumb } from '~components/Services/Intro'
import SEO from '~components/seo'

const PressItemHeader = styled.h3`
  font-size: 16px;
  font-weight: 600;
  line-height: 24px;
  text-transform: capitalize;
  font-family: 'Montserrat', sans-serif;
  color: ${scssVars.darkBrown};
`

const PressItemDate = styled.h4`
  font-size: 18px;
  font-weight: 400;
  line-height: 24px;
  padding-top: 0;
  text-transform: capitalize;
  font-family: 'EB Garamond', serif;
  color: ${scssVars.darkBrown};
`

const PageList = styled.ul`
  border-top: 1px solid ${scssVars.brown};
  list-style: none;
  li {
    padding: 0 0.5rem;
    a {
      color: ${scssVars.brown};
    }
    &:first-child {
      padding-left: 1rem;
    }
    &:last-child {
      padding-right: 1rem;
    }
  }
`

const PressItem = ({ press }) => (
  <Col xs="6" md="3" className="text-center pb-3">
    <img
      srcSet={`${press.pressListLogoMobile} 158w,
               ${press.pressListLogoDesktop} 474w`}
      sizes="(max-width: 768px) 158px
              474px"
      src={press.pressListLogoMobile}
      className="w-100 pb-2" alt={press.publication}
    />
    <PressItemHeader className="d-none d-lg-block">{press.publication}</PressItemHeader>
    <PressItemDate>{press.date}</PressItemDate>
    <a href={press.url || press.pressUpload} className="edit-link">Read more</a>
  </Col>
)

PressItem.propTypes = {
  press: PropTypes.shape({
    publication: PropTypes.string.isRequired,
    date: PropTypes.string.isRequired,
    url: PropTypes.string,
    pressListLogoMobile: PropTypes.object.isRequired,
    pressListLogoDesktop: PropTypes.object.isRequired,
    pressUpload: PropTypes.object
  })
}
export default function PressPage ({ data: { allMarkdownRemark: { edges } }, pageContext: { numPages, currentPage } }) {
  const [count, setCount] = useState(20)
  const pressItems = edges.map(edge => edge.node.frontmatter)
  return (
    <Layout>
      <SEO title="Press" pathname="/press" description="Read all about the latest Leather Spa news and care tips" />
      <main>
        <Breadcrumbs>
          <li>Home</li>
          <li>About</li>
          <li>Press</li>
        </Breadcrumbs>
        <MobileBreadcrumb>About</MobileBreadcrumb>
        <div className="d-lg-none pb-5">
          <Container>
            <Row>
              {pressItems.slice(0, count).map((item, i) => (
                <PressItem press={item} key={i} />
              ))}
            </Row>
            <button
              type="button"
              className="btn btn-block btn-outline-primary my-5"
              onClick={() => setCount(count + 20)}
              disabled={count >= pressItems.length}
            >
              Load More
            </button>
          </Container>
        </div>
        <div className="d-none d-lg-block">
          <Container className="px-xl-0" style={{ maxWidth: '1280px' }}>
            <FancyLineHeading type="h1" className="my-5">In The Press</FancyLineHeading>
            <Row>
              {pressItems.map((press, index) => (
                <PressItem press={press} key={`press-item-${index}`} />
              ))}
            </Row>
            <div className="text-center">
              <PageList className="d-none d-lg-inline-block text-center pl-0 my-5 ml-auto mr-auto pt-2">
                {Array.from({ length: numPages }, (_, i) => (
                  <li className="d-inline" key={`pagination-number${i + 1}`}>
                    <Link to={`/press/${i === 0 ? '' : i + 1}`} style={{ color: i + 1 === currentPage ? scssVars.orange : scssVars.brown }}>{i + 1}</Link>
                  </li>
                ))}
              </PageList>
            </div>
          </Container>
        </div>
      </main>
    </Layout>
  )
}

PressPage.propTypes = {
  data: PropTypes.object.isRequired,
  pageContext: PropTypes.object.isRequired
}

export const pagePressItems = graphql`
  query PressItemQuery($skip: Int!, $limit: Int!) {
    allMarkdownRemark(filter: {frontmatter: {templateKey: {eq: "press-page"}, type: {eq: "Press"}}}, sort: {fields: frontmatter___date, order: DESC}, limit: $limit, skip: $skip) {
      edges {
        node {
          frontmatter {
            publication
            date
            url
            pressListLogoMobile
            pressListLogoDesktop
            pressUpload
          }
        }
      }
    }
  }
`
